<template>
  <section
    :style="{
      height: '100vh',
    }"
  >
    <div class="grid grid-nogutter h-full md:flex-row bg-white">
      <div class="col-12 md:col-5">
        <div
          class="pod-image"
          :style="{
            backgroundImage: `url(${rectLogo})`,
          }"
        >
        </div>
      </div>
      <div class="col-12 md:col-7 bg-white">
        <div class="flex justify-content-center md:pt-8 px-3">
          <div class="login-form-container">
            <form @submit.prevent="onSubmit">
              <h1 class="text-center text-4xl">{{ title }}</h1>
              <BaseInput
                v-model="form.email"
                fieldId="email"
                fieldName="email"
                fieldLabel="Email"
              />
              <BasePassword
                v-model="form.password"
                fieldId="password"
                fieldName="password"
                fieldLabel="Password"
                class="w-full"
                inputClass="w-full"
                toggleMask
                :feedback="false"
              />
              <Button
                class="justify-content-center w-full"
                type="submit"
                :loading="isSubmitting"
                label="Submit"
              />
            </form>
            <p
              v-if="!disableSignup"
              class="block text-center"
            >
              Don't have an account?
              <router-link :to="{ name: ROUTE_SIGN_UP }">Sign up</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores';
import rectLogo from '@/assets/ekoz_rect.jpeg';
import { ROUTE_SIGN_UP } from '@/router/routes';
import { parseMessageFromError } from '@/utils/errors';

export default {
  props: {
    title: {
      type: String,
      default: 'Sign In',
    },
    disableSignup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ROUTE_SIGN_UP,
      isSubmitting: false,
      rectLogo,
      form: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    async onSubmit() {
      try {
        this.isSubmitting = true;

        const { email, password } = this.form;

        await this.authStore.login({ email, password });

        this.$router.push('/');
      } catch (error) {
        const message = parseMessageFromError(error, 'Invalid email or password');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 400px;
}
.pod-image {
  height: 140px;
  width: 100%;
  background-size: cover;
  background-position: center 25%;

  @media (min-width: 768px) {
    height: 100%;
    background-position: center;
  }
}
</style>
