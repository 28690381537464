<template>
  <PageContainer maxWidth="1200px">
    <div class="grid">
      <div class="col-12 sm:col-6">
        <router-link
          :to="{
            name: ROUTE_HOST_CAMPAIGNS,
          }"
        >
          <Button
            class="text-sm p-0"
            icon="pi pi-arrow-left"
            link
            label="Back to campaigns"
          />
        </router-link>
      </div>
      <div class="col-12 sm:col-6 flex justify-content-end">
        <Button
          v-if="campaignIsActive"
          label="Pause Campaign"
          outlined
          :loading="isPauseSubmitting"
          @click="isPauseDialogVisible = true"
        />
        <Button
          v-if="campaignIsPaused"
          label="Resume Campaign"
          outlined
          :loading="isResumeSubmitting"
          @click="isResumeDialogVisible = true"
        />
      </div>
    </div>

    <!-- TODO - add loading state -->
    <!-- TODO - add error state? -->

    <template v-if="compiledCampaign">
      <!-- header -->
      <div class="grid line-height-3 mt-3">
        <div class="col-12 md:col">
          <span class="text-sm text-gray-400">
            {{ compiledCampaign.org_name || '-' }}
          </span><br />
          <span class="text-lg font-bold">
            {{ compiledCampaign.campaign_name }}
          </span><br />
          <Chip
            v-if="statusChip"
            :class="[statusChip.class, 'mt-2 text-xs']"
            :label="statusChip.value"
            v-tooltip.top="'Status'"
          />
        </div>

        <div class="col-6 md:col">
          <span class="text-sm text-gray-400">
            Budget Allocation
          </span><br />
          <span class="text-lg font-bold">
            {{ compiledCampaign.budgetAllocationLabel }}
          </span>
        </div>

        <div class="col-6 md:col">
          <span class="text-sm text-gray-400">
            Duration
          </span><br />
          <span class="text-lg font-bold">
            {{ compiledCampaign.durationInDays }}
            day{{ compiledCampaign.durationInDays === 1 ? '' : 's' }}
          </span>
        </div>

        <div class="col-6 md:col">
          <span class="text-sm text-gray-400">
            Ends {{ compiledCampaign.endDateDisplay }}
          </span><br />
          <span class="text-lg font-bold">
            {{ compiledCampaign.durationSubtext }}
          </span>
        </div>

        <div class="col-6 md:col">
          <span class="text-sm text-gray-400">
            Budget
          </span><br />
          <span class="text-lg font-bold">
            {{ compiledCampaign.budgetDisplay }}
          </span>
        </div>
      </div>

      <Card
        class="mt-3 bg-gray-100"
        :pt="{
          body: {
            class: 'p-2'
          }
        }"
      >
        <template #content>
          <div class="flex">
            <div class="flex flex-grow-1 px-2">
              <audio
                class="w-full"
                controls
                :src="''"
              />
            </div>
            <!-- <Button
              class="text-sm"
              label="Transcribe"
              text
            /> -->
          </div>
        </template>
      </Card>

      <div class="flex">
        <h2>
          Campaign Details
        </h2>
      </div>
      <CampaignImpressionsCard
        :isLoading="analyticsStore.campaignImpressionsAreLoading[compiledCampaign.id] || false"
        :impressions="this.analyticsStore.campaignImpressions[this.$route.params.campaignId] || []"
      />

      <div class="mt-4">
        <h2>
          General Information
        </h2>
        <ul class="information-list">
          <li>
            <span class="text-sm text-gray-500">Target Gender</span><br />
            <Chip class="bg-white px-3 py-2 font-bold border-gray-200 mt-2 border-1">
              {{ compiledCampaign.genderLabel }}
            </Chip>
          </li>
          <li>
            <span class="text-sm text-gray-500">Ages</span><br />
            <Chip
              v-for="item in compiledCampaign.targetAges"
              :key="item"
              class="bg-white px-3 py-2 font-bold border-gray-200 mt-2 border-1"
            >
              {{ item }}
            </Chip>
          </li>
          <li>
            <span class="text-sm text-gray-500">Categories</span><br />
            <Chip
              v-for="item in compiledCampaign.targetCategoryLabels"
              :key="item"
              class="bg-white px-3 py-2 font-bold border-gray-200 mt-2 border-1"
            >
              {{ item }}
            </Chip>
          </li>
          <li>
            <span class="text-sm text-gray-500">Ad run slot</span><br/>
            <Chip class="bg-white px-3 py-2 font-bold border-gray-200 mt-2 border-1">
              {{ compiledCampaign.ad_run_slot }}
            </Chip>
          </li>
        </ul>
      </div>

      <!-- <Divider /> -->

      <!-- <div class="mt-4">
        <h2>
          Design Ad
        </h2>
        <ul class="flex flex-wrap list-none p-0">
          <li
            class="mr-2 mb-2"
            v-for="(item, index) in adInformation"
            :key="index"
          >
            <span class="text-sm text-gray-500">{{ item.key }}</span><br />
            <Chip class="bg-white px-3 py-2 font-bold border-gray-200 mt-2 border-1">
              {{ item.value }}
            </Chip>
          </li>
        </ul>
      </div> -->
    </template>

    <ConfirmDialog
      v-model:visible="isPauseDialogVisible"
      :isSubmitting="isPauseSubmitting"
      header="Pause Campaign"
      @confirm="onSubmitPauseCampaign"
    >
      <p>
        Are you sure you want to pause the campaign
        <strong>
          {{ compiledCampaign
            ? compiledCampaign.campaign_name
            : '-'
          }}
        </strong>
        ?
      </p>
      <p>
        Ads will stop serving until the campaign is resumed.
      </p>
    </ConfirmDialog>

    <ConfirmDialog
      v-model:visible="isResumeDialogVisible"
      :isSubmitting="isResumeSubmitting"
      header="Resume Campaign"
      @confirm="onSubmitResumeCampaign"
    >
      <p>
        Are you sure you want to resume the campaign
        <strong>
          {{ compiledCampaign
            ? compiledCampaign.campaign_name
            : '-'
          }}
        </strong>
        ?
      </p>
    </ConfirmDialog>
  </PageContainer>
</template>

<script>
import { DateTime } from 'luxon';
import {
  BUDGET_ALLOCATION_DICTIONARY,
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_PAUSED,
  GENDER_OPTIONS,
} from '@/constants';
import { ROUTE_HOST_CAMPAIGNS } from '@/router/routes';
import {
  useCampaignsStore,
  useCategoriesStore,
  useMyUserStore,
  useAnalyticsStore,
} from '@/stores';
import { mapStores } from 'pinia';
import { isJsonString } from '@/utils/helpers';
import { parseMessageFromError } from '@/utils/errors';
import ConfirmDialog from '@/components/confirmDialog';
import * as api from '@/api';
import { getCampaignStatusChip } from '@/utils/statuses';
import CampaignImpressionsCard from '@/components/campaignImpressionsCard';

export default {
  components: {
    ConfirmDialog,
    CampaignImpressionsCard,
  },
  computed: {
    ...mapStores(useMyUserStore, useCampaignsStore, useCategoriesStore, useAnalyticsStore),
    compiledCampaign() {
      const campaign = this.campaignsStore.getCampaignById(this.$route.params.campaignId);

      if (!campaign) return null;

      const budgetAllocationLabel = BUDGET_ALLOCATION_DICTIONARY[campaign.budget_allocation]
        ? BUDGET_ALLOCATION_DICTIONARY[campaign.budget_allocation].label
        : campaign.budget_allocation;

      const durationInDays = Math.ceil(
        DateTime.fromISO(campaign.end_date)
          .diff(DateTime.fromISO(campaign.start_date), ['days']).days,
      );

      const endDateDisplay = DateTime.fromISO(campaign.end_date)
        .toLocaleString(DateTime.DATE_SHORT);

      const campaignHasStarted = DateTime.fromISO(campaign.start_date).diffNow('days').days < 0;
      let durationSubtext = '';

      if (campaignHasStarted) {
        const daysLeft = Math.max(
          Math.ceil(DateTime.fromISO(campaign.end_date).diffNow('days').days),
          0,
        );

        durationSubtext = `${daysLeft} day${daysLeft === 1 ? '' : 's'} left`;
      } else {
        const daysUntilStart = Math.ceil(DateTime.fromISO(campaign.start_date).diffNow('days').days);

        durationSubtext = `${daysUntilStart} day${daysUntilStart === 1 ? '' : 's'} until start`;
      }

      const budgetDisplay = !Number.isNaN(Number(campaign.max_budget))
        ? Number(campaign.max_budget).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          style: 'currency',
          currency: 'USD',
        }).toString()
        : '0';

      const targetAges = isJsonString(campaign.target_ages)
        ? Object.keys(JSON.parse(campaign.target_ages))
        : [];

      const targetCategories = isJsonString(campaign.target_categories)
        ? JSON.parse(campaign.target_categories)
        : [];

      const targetCategoryLabels = targetCategories.reduce((acc, item) => {
        const matchingCategory = this.categoriesStore.categoryDictionary[Number(item)];

        if (matchingCategory) acc.push(matchingCategory.name);

        return acc;
      }, []);

      const genderLabel = GENDER_OPTIONS.some((item) => item.value === campaign.target_gender)
        ? GENDER_OPTIONS.find((item) => item.value === campaign.target_gender).label
        : campaign.target_gender;

      return {
        ...campaign,
        budgetAllocationLabel,
        durationInDays,
        endDateDisplay,
        durationSubtext,
        budgetDisplay,
        targetAges,
        targetCategoryLabels,
        genderLabel,
      };
    },
    campaignIsActive() {
      return this.compiledCampaign && this.compiledCampaign.status === CAMPAIGN_STATUS_ACTIVE;
    },
    campaignIsPaused() {
      return this.compiledCampaign && this.compiledCampaign.status === CAMPAIGN_STATUS_PAUSED;
    },
    statusChip() {
      if (!this.compiledCampaign) return null;

      return getCampaignStatusChip(this.compiledCampaign.status);
    },
  },
  data() {
    return {
      ROUTE_HOST_CAMPAIGNS,
      isPauseDialogVisible: false,
      isPauseSubmitting: false,
      isResumeDialogVisible: false,
      isResumeSubmitting: false,
      // adInformation: [
      //   {
      //     key: 'Title',
      //     value: 'Test Title',
      //   },
      //   {
      //     key: 'Ad Type',
      //     value: 'Personal Endorsement',
      //   },
      //   {
      //     key: 'Ad Length',
      //     value: '30s',
      //   },
      //   {
      //     key: 'Url',
      //     value: 'Link',
      //   },
      //   {
      //     key: 'Coupon Code',
      //     value: 'Ekoz 2024',
      //   },
      //   {
      //     key: 'Ads run',
      //     value: 'Mid-roll',
      //   },
      // ],
    };
  },
  async mounted() {
    try {
      await this.campaignsStore.getCampaigns({
        organizationId: this.myUserStore.myOrganization.id,
      });

      const campaign = this.campaignsStore.getCampaignById(this.$route.params.campaignId);

      await this.analyticsStore.getCampaignImpressions({
        campaignId: campaign.id,
        startDate: campaign.start_date,
        endDate: campaign.end_date,
      });
    } catch (error) {
      const message = parseMessageFromError(error, 'Error loading campaign.');

      this.$toast.add({
        severity: 'error',
        detail: message,
      });
    }
  },
  methods: {
    async onSubmitPauseCampaign() {
      try {
        this.isPauseSubmitting = true;

        await api.pauseCampaign({
          campaignId: this.compiledCampaign.id,
          action: 'pause',
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully paused campaign',
        });

        this.isPauseDialogVisible = false;

        await this.campaignsStore.getCampaigns({
          organizationId: this.myUserStore.myOrganization.id,
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error pausing campaign.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isPauseSubmitting = false;
      }
    },
    async onSubmitResumeCampaign() {
      try {
        this.isResumeSubmitting = true;

        await api.pauseCampaign({
          campaignId: this.compiledCampaign.id,
          action: 'resume',
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully resumed campaign',
        });

        this.isResumeDialogVisible = false;

        await this.campaignsStore.getCampaigns({
          organizationId: this.myUserStore.myOrganization.id,
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error resuming campaign.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isResumeSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.information-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  >li {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-right: 0;
    }

    .p-chip {
      margin-right: 0.25rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
