import ekozAxios from './instance/axios';

export const createProgram = ({
  organizationId,
  name,
  description,
  contactName,
  contactEmail,
  averageListeners,
  tapProgramId,
  spreakerShowId,
  targetGender,
  targetAgeRanges,
  adPreferences,
  categories,
  blacklistedCategories,
  approvalBehavior,
  rssUrl,
}) => ekozAxios.request({
  method: 'post',
  url: '/programs/new_program.php',
  data: {
    name,
    description,
    contact_name: contactName,
    contact_email: contactEmail,
    organization_id: organizationId,
    avg_listeners: averageListeners,
    tap_program_id: tapProgramId,
    spreaker_show_id: spreakerShowId,
    target_gender: targetGender,
    target_age_ranges: targetAgeRanges,
    ad_preferences: adPreferences,
    categories,
    blacklisted_categories: blacklistedCategories,
    approval_behavior: approvalBehavior,
    rss_url: rssUrl,
  },
});

export const readPrograms = ({
  organizationId,
}) => ekozAxios.request({
  method: 'get',
  url: '/programs/get_programs.php',
  params: {
    organization_id: organizationId,
  },
});

export const updateProgram = ({
  programId,
  name,
  description,
  contactName,
  contactEmail,
  averageListeners,
  tapProgramId,
  spreakerShowId,
  targetGender,
  targetAgeRanges,
  adPreferences,
  categories,
  blacklistedCategories,
  approvalBehavior,
  rssUrl,
}) => ekozAxios.request({
  method: 'post',
  url: '/programs/edit_program.php',
  data: {
    id: programId,
    name,
    description,
    contact_name: contactName,
    contact_email: contactEmail,
    avg_listeners: averageListeners,
    tap_program_id: tapProgramId,
    spreaker_show_id: spreakerShowId,
    target_gender: targetGender,
    target_age_ranges: targetAgeRanges,
    ad_preferences: adPreferences,
    categories,
    blacklisted_categories: blacklistedCategories,
    approval_behavior: approvalBehavior,
    rss_url: rssUrl,
  },
});

export const deleteProgram = (programId) => ekozAxios.request({
  method: 'get',
  url: '/programs/delete_program.php',
  params: {
    id: programId,
  },
});

export const scrapeProgramMetadata = ({
  programUrl,
}) => ekozAxios.request({
  method: 'get',
  url: '/programs/scrape_program_metadata.php',
  params: {
    url: programUrl,
  },
});

export const validateProgram = ({
  code,
  email,
}) => ekozAxios.request({
  method: 'get',
  url: '/programs/validate_program_email.php',
  params: {
    code,
    email,
  },
});
