import {
  AD_LENGTH_15_SECONDS,
  AD_LENGTH_30_SECONDS,
  AD_LENGTH_60_SECONDS,
  AD_LENGTH_90_SECONDS,
  AD_RUN_SLOT_MID_ROLL,
  AD_RUN_SLOT_POST_ROLL,
  AD_RUN_SLOT_PRE_ROLL,
  USER_TYPE_ADVERTISER,
  USER_TYPE_HOST,
} from '@/constants';

const BID_RECOMMENDATIONS = {
  // advertiser pre roll
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_PRE_ROLL}_${AD_LENGTH_15_SECONDS}`]: {
    min: 18,
    max: 22,
  },
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_PRE_ROLL}_${AD_LENGTH_30_SECONDS}`]: {
    min: 20,
    max: 27,
  },
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_PRE_ROLL}_${AD_LENGTH_60_SECONDS}`]: {
    min: 22,
    max: 29,
  },
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_PRE_ROLL}_${AD_LENGTH_90_SECONDS}`]: {
    min: 25,
    max: 30,
  },
  // advertiser mid roll
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_MID_ROLL}_${AD_LENGTH_15_SECONDS}`]: {
    min: 18,
    max: 22,
  },
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_MID_ROLL}_${AD_LENGTH_30_SECONDS}`]: {
    min: 20,
    max: 27,
  },
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_MID_ROLL}_${AD_LENGTH_60_SECONDS}`]: {
    min: 22,
    max: 29,
  },
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_MID_ROLL}_${AD_LENGTH_90_SECONDS}`]: {
    min: 25,
    max: 30,
  },
  // advertiser post roll
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_POST_ROLL}_${AD_LENGTH_15_SECONDS}`]: {
    min: 15,
    max: 19,
  },
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_POST_ROLL}_${AD_LENGTH_30_SECONDS}`]: {
    min: 18,
    max: 22,
  },
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_POST_ROLL}_${AD_LENGTH_60_SECONDS}`]: {
    min: 20,
    max: 25,
  },
  [`${USER_TYPE_ADVERTISER}_${AD_RUN_SLOT_POST_ROLL}_${AD_LENGTH_90_SECONDS}`]: {
    min: 22,
    max: 25,
  },
  // host pre roll
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_PRE_ROLL}_${AD_LENGTH_15_SECONDS}`]: {
    min: 10,
    max: 12,
  },
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_PRE_ROLL}_${AD_LENGTH_30_SECONDS}`]: {
    min: 15,
    max: 20,
  },
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_PRE_ROLL}_${AD_LENGTH_60_SECONDS}`]: {
    min: 18,
    max: 22,
  },
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_PRE_ROLL}_${AD_LENGTH_90_SECONDS}`]: {
    min: 20,
    max: 25,
  },
  // host mid roll
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_MID_ROLL}_${AD_LENGTH_15_SECONDS}`]: {
    min: 10,
    max: 12,
  },
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_MID_ROLL}_${AD_LENGTH_30_SECONDS}`]: {
    min: 15,
    max: 20,
  },
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_MID_ROLL}_${AD_LENGTH_60_SECONDS}`]: {
    min: 18,
    max: 22,
  },
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_MID_ROLL}_${AD_LENGTH_90_SECONDS}`]: {
    min: 20,
    max: 25,
  },
  // host post roll
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_POST_ROLL}_${AD_LENGTH_15_SECONDS}`]: {
    min: 8,
    max: 10,
  },
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_POST_ROLL}_${AD_LENGTH_30_SECONDS}`]: {
    min: 10,
    max: 12,
  },
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_POST_ROLL}_${AD_LENGTH_60_SECONDS}`]: {
    min: 12,
    max: 15,
  },
  [`${USER_TYPE_HOST}_${AD_RUN_SLOT_POST_ROLL}_${AD_LENGTH_90_SECONDS}`]: {
    min: 15,
    max: 20,
  },
};

/**
 * Get bid recommendation based on inputs
 *
 * @param {string} userType
 * @param {string} adRunSlot
 * @param {string} adLength
 * @returns {Object} recommendation
 * @returns {number|null} recommendation.min
 * @returns {number|null} recommendation.max
 */
export const getBidRecommendation = (userType, adRunSlot, adLength) => {
  const compoundKey = `${userType}_${adRunSlot}_${adLength}`;
  return BID_RECOMMENDATIONS[compoundKey]
    ? { ...BID_RECOMMENDATIONS[compoundKey] }
    : {
      min: null,
      max: null,
    };
};
