<template>
  <div class="campaign-container">
    <div
      ref="formBody"
      class="form-container pt-3"
    >
      <!-- Step 1 -->
      <Card v-if="activeStep === 1">
        <template #content>
          <WizardStepHeader
            :stepNumber="1"
            title="Campaign, Target, and Categories"
          />

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepOneSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="campaign-form"
              @submit.prevent="handleSubmit($event, onSubmitStepOne)"
            >
              <BaseInput
                v-model="stepOneForm.name"
                fieldId="name"
                fieldName="name"
                fieldLabel="Ad Campaign Name"
              />

              <BaseFieldContainer>
                <div class="mb-2 font-bold">Target Gender</div>
                <GenderInput
                  v-model="stepOneForm.targetGender"
                />
              </BaseFieldContainer>

              <Divider />

              <BaseFieldContainer>
                <div class="mb-2 font-bold">Target Age</div>
                <TargetAgeInput
                  v-model="stepOneForm.targetAgeRanges"
                />
              </BaseFieldContainer>

              <BaseFieldContainer>
                <div class="mb-2 font-bold">Geographic Targeting</div>
                <GeographicTargetMessage />
              </BaseFieldContainer>

              <Divider />

              <CategoryInput
                fieldLabel="Podcast Categories"
                fieldName="categories"
                :categoryOptions="categoryOptions"
                v-model="stepOneForm.categories"
                helperText="Choose all categories that describe your product or service."
              />

              <Divider />

              <BaseCheckbox
                v-model="stepOneForm.allowExplicit"
                fieldId="allowExplicitContent"
                fieldName="allowExplicit"
                fieldLabel="Include shows with explicit content"
                binary
                :trueValue="1"
                :falseValue="0"
              />
            </form>
          </VeeForm>
        </template>
      </Card>

      <!-- Step 2 -->
      <Card v-else-if="activeStep === 2">
        <template #content>
          <WizardStepHeader
            :stepNumber="2"
            title="Budget and Ads info"
          />

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepTwoSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="campaign-form"
              @submit.prevent="handleSubmit($event, onSubmitStepTwo)"
            >
              <div class="grid">
                <div class="col-12 sm:col-6">
                  <BaseInputNumber
                    v-model="stepTwoForm.budget"
                    fieldId="budget"
                    fieldName="budget"
                    fieldLabel="Budget"
                    placeholder="Enter your budget for this campaign"
                    mode="currency"
                    currency="USD"
                  />
                </div>

                <div class="col-12 sm:col-6">
                  <BaseSelectButton
                    v-model="stepTwoForm.budgetAllocation"
                    fieldId="budgetAllocation"
                    fieldName="budgetAllocation"
                    fieldLabel="Budget allocation"
                    :options="BUDGET_ALLOCATION_OPTIONS"
                    :allowEmpty="false"
                  />
                </div>

                <div class="col-12 sm:col-4">
                  <BaseDropdown
                    v-model="stepTwoForm.adRunSlot"
                    fieldId="adRunSlot"
                    fieldName="adRunSlot"
                    fieldLabel="Ad Run Slot"
                    :options="AD_RUN_SLOT_OPTIONS"
                    placeholder="Choose when the ad should run"
                  />
                </div>

                <div class="col-12 sm:col-4">
                  <BaseDropdown
                    v-model="stepTwoForm.adLength"
                    fieldId="adLength"
                    fieldName="adLength"
                    fieldLabel="Ad Length"
                    class="flex-grow-1"
                    placeholder="Choose an ad length"
                    :options="AD_LENGTH_OPTIONS"
                  />
                </div>
              </div>

              <div class="grid">
                <div class="col-12">
                  <Divider />
                </div>
              </div>

              <div class="grid">
                <div class="col-12 sm:col-8">
                  <BaseInputNumber
                    v-model="stepTwoForm.bidCap"
                    fieldId="bidCap"
                    fieldName="bidCap"
                    fieldLabel="Bid Cap"
                    mode="currency"
                    currency="USD"
                    helperText="Maximum bid per 1,000 impressions"
                  />

                  <BidWarning
                    class="mb-2"
                    :userType="USER_TYPE_ADVERTISER"
                    :adRunSlot="stepTwoForm.adRunSlot"
                    :adLength="stepTwoForm.adLength"
                    :cpm="stepTwoForm.bidCap"
                  />
                  <BidRecommendation
                    v-if="stepTwoForm.adRunSlot && stepTwoForm.adLength"
                    :userType="USER_TYPE_ADVERTISER"
                    :adRunSlot="stepTwoForm.adRunSlot"
                    :adLength="stepTwoForm.adLength"
                  />
                </div>
              </div>

              <div class="grid">
                <div class="col-12 sm:col-8">
                  <BaseInputNumber
                    v-model="stepTwoForm.frequencyCap"
                    fieldId="frequencyCap"
                    fieldName="frequencyCap"
                    fieldLabel="Frequency Cap"
                    helperText="Maximum number of times a user can
                      receive the ad within a single ad break."
                  />
                </div>
              </div>

              <div class="grid">
                <div class="col-12">
                  <Divider />
                </div>

                <div class="col-12 sm:col-6">
                  <BaseCalendar
                    v-model="stepTwoForm.dates"
                    fieldId="campaignDates"
                    fieldName="dates"
                    fieldLabel="Campaign Dates"
                    :numberOfMonths="2"
                    selectionMode="range"
                    :manualInput="false"
                  />
                </div>
              </div>
            </form>
          </VeeForm>
        </template>
      </Card>

      <!-- Step 3 -->
      <Card v-else-if="activeStep === 3">
        <template #content>
          <WizardStepHeader
            :stepNumber="3"
            title="Design your ad"
          />

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepThreeSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="campaign-form"
              @submit.prevent="handleSubmit($event, onSubmitStepThree)"
            >
              <div class="grid">
                <div class="col-12">
                  <BaseInput
                    v-model="stepThreeForm.adTitle"
                    fieldId="adTitle"
                    fieldName="adTitle"
                    fieldLabel="Title"
                    :characterLimit="AD_TITLE_CHARACTER_LIMIT"
                  />
                </div>

                <div class="col-12">
                  <Message
                    class="mt-0"
                    severity="secondary"
                    icon="pi pi-info-circle"
                    :closable="false"
                    :pt="{
                      icon: {
                        class: 'mt-1 text-xl align-self-start',
                      },
                      text: {
                        class: 'font-normal text-sm',
                      },
                    }"
                  >
                    Be advised that personal endorsements are not currently available.
                    Do not add any language suggesting a personal endorsement.
                  </Message>

                  <TabView v-model:activeIndex="adCopyTabIndex">
                    <TabPanel header="Generate AI Ad Copy">
                      <small>
                        Upload the "talking points" document for your ad.
                        This document describes the product or service being advertised.
                      </small>
                      <VeeField
                        name="adDocument"
                        v-model="stepThreeForm.adDocument"
                        v-slot="{ errors }"
                      >
                        <SingleFileUpload
                          v-model="stepThreeForm.adDocument"
                          :invalid="errors.length > 0"
                          :error="errors[0]"
                        />
                      </VeeField>

                      <template
                        v-if="documentAdUrl || isDocumentAdLoading"
                      >
                        <Divider />

                        <div class="font-bold">
                          Example Ad
                        </div>
                        <p class="text-sm">
                          This is an example ad based on your settings
                          and one of our demo voiceprints.
                          <span class="inline-block mt-2">
                            <strong>Note:</strong> This example uses a demo
                              voiceprint. The ads generated for the campaign will be
                              personalized to the voiceprint of each host.
                          </span>
                        </p>

                        <AudioCard
                          class="mt-3"
                          :url="documentAdUrl"
                          :isLoading="isDocumentAdLoading"
                        />

                        <div class="mt-3">
                          <small>
                            This is the ad script from the audio above.<br />
                            <strong>Note:</strong> The script may slightly
                            vary for each individual host.
                          </small>
                          <Skeleton
                            v-if="isDocumentAdLoading"
                            class="mt-2"
                            height="74px"
                          />
                          <Textarea
                            v-else
                            class="w-full mt-2"
                            :value="documentAdScript"
                            disabled
                            :autoResize="true"
                          />
                        </div>
                      </template>
                    </TabPanel>
                    <TabPanel header="Read Script Verbatim">
                      <BaseTextarea
                        v-model="stepThreeForm.campaignScript"
                        fieldId="campaignScript"
                        fieldName="campaignScript"
                        helperText="Add your script that should be read verbatim."
                      />

                      <Button
                        v-if="showGenerateVerbatimAdButton"
                        label="Generate Example Ad"
                        outlined
                        @click="onGenerateVerbatimAd"
                      />

                      <Divider />

                      <template v-if="verbatimAdIsLoading || verbatimAdUrl">
                        <div class="font-bold">
                          Example Ad
                        </div>
                        <p class="text-sm">
                          This is an example ad based on your settings
                          and one of our demo voiceprints.
                          <span class="inline-block mt-2">
                            <strong>Note:</strong> This example uses a demo
                              voiceprint. The ads generated for the campaign will use
                              the voiceprint of each host.
                          </span>
                        </p>

                        <AudioCard
                          class="mt-3"
                          :url="verbatimAdUrl"
                          :isLoading="verbatimAdIsLoading"
                        />
                      </template>
                    </TabPanel>
                  </TabView>
                </div>

                <div class="col-12">
                  <BaseInput
                  v-model="stepThreeForm.trackingPixel"
                  fieldId="trackingPixel"
                  fieldName="trackingPixel"
                  fieldLabel="Tracking Pixel URL"
                  />
                </div>
              </div>
            </form>
          </VeeForm>
        </template>
      </Card>

      <CampaignSummaryCard
        class="campaign-form-summary-side"
        :campaignName="stepOneForm.name"
        :startDate="stepTwoForm.dates && stepTwoForm.dates[0]
          ? stepTwoForm.dates[0]
          : null"
        :endDate="stepTwoForm.dates && stepTwoForm.dates[1]
          ? stepTwoForm.dates[1]
          : null"
        :budget="stepTwoForm.budget"
        :bidCap="stepTwoForm.bidCap"
        :adRunSlot="stepTwoForm.adRunSlot"
        :adLength="stepTwoForm.adLength"
        :adType="adType"
      />

      <ConfirmDialog
        v-model:visible="unsavedChangesDialogIsVisible"
        header="You have unsaved changes"
        @confirm="() => {
          this.formHasChanges = false;
          this.unsavedChangesDialogIsVisible = false;
          this.onCancel();
        }"
        confirmButtonLabel="Leave"
        cancelButtonLabel="Stay"
      >
        <p>
          There are unsaved changes to your campaign.
          Are you sure you want to leave?
        </p>
      </ConfirmDialog>
    </div>

    <div class="form-container flex justify-content-end py-4">
      <Button
        text
        plain
        :label="previousButtonText"
        :disabled="isSubmitting"
        @click="onClickPrevious"
      />
      <Button
        class="ml-2"
        :label="nextButtonText"
        type="submit"
        form="campaign-form"
        :loading="isSubmitting"
      />
    </div>
  </div>
</template>

<script>
import {
  array,
  date,
  mixed,
  number,
  object,
  string,
} from 'yup';
import GenderInput from '@/components/genderInput';
import TargetAgeInput from '@/components/targetAgeInput';
import {
  AGE_GROUP_OPTIONS,
  AD_RUN_SLOT_OPTIONS,
  BUDGET_ALLOCATION_OPTIONS,
  BUDGET_ALLOCATION_UNIFORM_BUDGET,
  AD_LENGTH_OPTIONS,
  AD_LENGTH_30_SECONDS,
  GENDER_ALL,
  USER_TYPE_ADVERTISER,
} from '@/constants';
import * as api from '@/api';
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';
import { parseMessageFromError } from '@/utils/errors';
import CategoryInput from '@/components/categoryInput';
import GeographicTargetMessage from '@/components/geographicTargetMessage';
import { ExistingDocument } from '@/utils/document';
import WizardStepHeader from './wizardStepHeader';
import SingleFileUpload from './singleFileUpload';
import CampaignSummaryCard from './campaignSummaryCard';
import BidRecommendation from './bidRecommendation';
import BidWarning from './bidWarning';
import ConfirmDialog from './confirmDialog';
import AudioCard from './audioCard';

const AD_TITLE_CHARACTER_LIMIT = 255;
const AD_COPY_DOCUMENT_INDEX = 0;
const AD_COPY_SCRIPT_INDEX = 1;

const DEMO_USER_ID = process.env.VUE_APP_DEMO_VOICE_USER_ID;

const generateEmptyStepOneForm = () => ({
  name: '',
  targetGender: GENDER_ALL,
  targetAgeRanges: [...AGE_GROUP_OPTIONS],
  allowExplicit: 0,
  categories: [],
});
const generateEmptyStepTwoForm = () => ({
  budget: null,
  adRunSlot: null,
  dates: [],
  budgetAllocation: BUDGET_ALLOCATION_UNIFORM_BUDGET,
  bidCap: null,
  frequencyCap: null,
  adLength: AD_LENGTH_30_SECONDS,
});
const generateEmptyStepThreeForm = () => ({
  adTitle: '',
  campaignScript: '',
  adDocument: null,
  trackingPixel: '',
});

export default {
  components: {
    WizardStepHeader,
    TargetAgeInput,
    SingleFileUpload,
    GenderInput,
    CategoryInput,
    CampaignSummaryCard,
    BidRecommendation,
    BidWarning,
    ConfirmDialog,
    AudioCard,
    GeographicTargetMessage,
  },
  props: {
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    campaign: {
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    categoryOptions: {
      type: Array,
      default: () => ([]),
    },
    initialPreviousButtonText: {
      type: String,
      default: 'Cancel',
    },
  },
  computed: {
    previousButtonText() {
      switch (this.activeStep) {
        case 1:
          return this.initialPreviousButtonText;
        case 2:
          return 'Previous';
        default:
          return 'Previous';
      }
    },
    nextButtonText() {
      switch (this.activeStep) {
        case 3:
          return 'Save and Approve';
        default:
          return 'Next';
      }
    },
    documentAdParams() {
      return {
        adLength: this.stepTwoForm.adLength,
        adDocument: this.stepThreeForm.adDocument,
      };
    },
    showGenerateVerbatimAdButton() {
      return !!DEMO_USER_ID;
    },
    adType() {
      switch (this.adCopyTabIndex) {
        case AD_COPY_DOCUMENT_INDEX:
          return 'AI Generated Script';
        case AD_COPY_SCRIPT_INDEX:
          return 'Verbatim Script';
        default:
          return 'N/A';
      }
    },
  },
  watch: {
    activeStep() {
      if (this.$refs.formBody) {
        this.$refs.formBody.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
    documentAdParams: {
      immediate: true,
      async handler() {
        await this.generateDocumentAd();
      },
    },
  },
  data() {
    return {
      // unsaved changes feature
      formHasChanges: false,
      stepOneUnwatch: null,
      stepTwoUnwatch: null,
      stepThreeUnwatch: null,
      unsavedChangesDialogIsVisible: false,

      AD_RUN_SLOT_OPTIONS,
      BUDGET_ALLOCATION_OPTIONS,
      AD_LENGTH_OPTIONS,
      AD_TITLE_CHARACTER_LIMIT,
      USER_TYPE_ADVERTISER,
      activeStep: 1,
      stepOneSchema: object({
        name: string().required('Name is required'),
      }),
      stepOneForm: generateEmptyStepOneForm(),
      stepTwoSchema: object({
        budget: number().required('Budget is required'),
        adRunSlot: string().required('Ad run slot is required'),
        bidCap: number().required('Bid cap is required'),
        frequencyCap: number().required('Frequency cap is required'),
        dates: array()
          .length(2, 'Must have start & end dates')
          .required('Campaign dates are required')
          .of(date().required('Must have start & end dates')),
      }),
      stepTwoForm: generateEmptyStepTwoForm(),
      stepThreeSchema: object({
        adTitle: string().required('Ad title is required').max(AD_TITLE_CHARACTER_LIMIT, `Ad title can be a max of ${AD_TITLE_CHARACTER_LIMIT} characters.`),
        campaignScript: string().when([], {
          is: () => this.adCopyTabIndex === AD_COPY_SCRIPT_INDEX,
          then: (schema) => schema.required('Ad script is required'),
          otherwise: (schema) => schema,
        }),
        adDocument: mixed().when([], {
          is: () => this.adCopyTabIndex === AD_COPY_DOCUMENT_INDEX,
          then: (schema) => schema.required('Ad Document is required'),
          otherwise: (schema) => schema.nullable(),
        }),
        trackingPixel: string().url('Tracking pixel must be a valid URL'),
      }),
      stepThreeForm: generateEmptyStepThreeForm(),
      adCopyTabIndex: AD_COPY_DOCUMENT_INDEX,
      // demo document ad
      isDocumentAdLoading: false,
      documentAdUrl: '',
      documentAdScript: '',
      // demo verbatim ad
      verbatimAdIsLoading: false,
      verbatimAdUrl: '',
    };
  },
  mounted() {
    this.activeStep = 1;

    this.stepOneForm = generateEmptyStepOneForm();
    this.stepTwoForm = generateEmptyStepTwoForm();
    this.stepThreeForm = generateEmptyStepThreeForm();

    this.adCopyTabIndex = AD_COPY_DOCUMENT_INDEX;

    // set up form changes watchers
    this.formHasChanges = false;

    if (this.stepOneUnwatch) this.stepOneUnwatch();
    this.stepOneUnwatch = this.$watch('stepOneForm', function stepOneWatch() {
      this.formHasChanges = true;
    }, {
      deep: true,
    });

    if (this.stepTwoUnwatch) this.stepTwoUnwatch();
    this.stepTwoUnwatch = this.$watch('stepTwoForm', function stepTwoWatch() {
      this.formHasChanges = true;
    }, {
      deep: true,
    });

    if (this.stepThreeUnwatch) this.stepThreeUnwatch();
    this.stepThreeUnwatch = this.$watch('stepThreeForm', function stepThreeWatch() {
      this.formHasChanges = true;
    }, {
      deep: true,
    });

    window.addEventListener('beforeunload', this.beforeUnload);

    // clear demo ad url
    this.documentAdUrl = '';
    this.documentAdScript = '';
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  },
  methods: {
    onCancel() {
      if (this.formHasChanges) {
        this.unsavedChangesDialogIsVisible = true;
        return;
      }

      this.$emit('cancel', true);
    },
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    beforeUnload(event) {
      if (this.formHasChanges) {
        event.preventDefault();
        // eslint-disable-next-line no-alert
        alert('There are unsaved changes to your campaign. Are you sure you want to leave?');
      }
    },
    onSubmitStepOne() {
      this.activeStep = 2;
    },
    onSubmitStepTwo() {
      this.activeStep = 3;
    },
    onSubmitStepThree() {
      let campaignScript = null;
      let adDocument = null;

      switch (this.adCopyTabIndex) {
        case AD_COPY_DOCUMENT_INDEX:
          // clear script if document is used
          campaignScript = null;
          adDocument = this.stepThreeForm.adDocument;
          break;
        case AD_COPY_SCRIPT_INDEX:
          // clear document is script is used
          campaignScript = this.stepThreeForm.campaignScript;
          adDocument = null;
          break;
        default:
            // do nothing
      }

      this.$emit('submit', {
        campaign: this.campaign,
        campaignForm: {
          ...this.stepOneForm,
          ...this.stepTwoForm,
          ...this.stepThreeForm,
          campaignScript,
          adDocument,
        },
      });
    },
    onClickPrevious() {
      switch (this.activeStep) {
        case 1:
          this.onCancel();
          break;
        case 2:
          this.activeStep = 1;
          break;
        case 3:
          this.activeStep = 2;
          break;
        default:
          // do nothing
      }
    },
    async generateDocumentAd() {
      if (!DEMO_USER_ID
        || !this.documentAdParams.adLength
        || !this.documentAdParams.adDocument
        || (this.documentAdParams.adDocument
          && this.documentAdParams.adDocument instanceof ExistingDocument)
      ) {
        return;
      }

      try {
        this.isDocumentAdLoading = true;

        const scriptMinutes = Math.floor(this.documentAdParams.adLength / 60);
        const scriptSeconds = this.documentAdParams.adLength % 60;

        const res = await api.createGeneratedAd({
          userId: DEMO_USER_ID,
          adFile: this.documentAdParams.adDocument,
          scriptMinutes,
          scriptSeconds,
        });

        this.documentAdUrl = res.s3_url;
        this.documentAdScript = res.script;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error generating demo ad');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isDocumentAdLoading = false;
      }
    },
    async onGenerateVerbatimAd() {
      try {
        this.verbatimAdIsLoading = true;

        const res = await api.createTextToSpeech({
          userId: DEMO_USER_ID,
          text: this.stepThreeForm.campaignScript,
        });

        this.verbatimAdUrl = res.s3_url;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error generating ad verbatim.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.verbatimAdIsLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.campaign-form-dialog {
  padding-top: 58px;

  @media screen and (
    min-width: #{map-get($breakpoints, 'md')}
  ) {
    padding-top: 0;
    padding-right: 260px;
  }
  @media screen and (
    min-width: #{map-get($breakpoints, 'lg')}
  ) {
    padding-right: 300px;
  }
}
</style>

<style lang="scss" scoped>
@import "@/styles/variables";

.form-container {
  max-width: 800px;
  width: 100%;
}

.campaign-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 58px;

  @media screen and (
    min-width: #{map-get($breakpoints, 'md')}
  ) {
    padding-top: 0;
    padding-right: 260px;
  }
  @media screen and (
    min-width: #{map-get($breakpoints, 'lg')}
  ) {
    padding-right: 300px;
  }
}

.campaign-form-summary-side {
  top: 67px;
}
</style>
