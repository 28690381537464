<template>
  <div class="field flex flex-column">
    <div class="mb-2 font-bold">
      Ad preferences
    </div>
    <small class="inline-block mb-2">
      <p>
        This table represents the recommended mimimum gross CPM
        that an advertiser must bid to run ads on your podcasts.
      </p>
      <p>
        <strong>Note</strong>: The lower the CPM, the more likely you will fill your ad inventory.
      </p>
      <p>
        <strong>Note</strong>: gross CPM is the price before other fees are taken out,
        including agency, sales commissions, and platform fees.
      </p>
    </small>

    <div class="overflow-x-auto">
      <table class="minimum-bid-cpm-table">
        <tbody>
          <tr class="font-bold text-right">
            <td><!-- blank --></td>
            <td
              :class="{
                'bg-primary-100': focusedParams[column.value],
              }"
              v-for="column in AD_LENGTH_HEADER_ROW"
              :key="column.value"
            >
              <InputGroup class="align-items-center justify-content-end">
                {{ column.label }}
                <Button
                  class="ml-1"
                  :icon="columnDisabledStatus[column.value]
                    ? 'pi pi-ban'
                    : 'pi pi-check-circle'"
                  size="small"
                  :severity="columnDisabledStatus[column.value]
                    ? 'error'
                    : 'success'"
                  text
                  v-tooltip.top="columnDisabledStatus[column.value]
                    ? 'Enable Column'
                    : 'Disable Column'"
                  @click="onToggleColumn(!columnDisabledStatus[column.value], column.value)"
                />
              </InputGroup>
            </td>
          </tr>
          <tr
            v-for="row in compiledAdPreferences"
            :key="row.adRunSlot"
          >
            <td
              :class="{
                'bg-primary-100': focusedParams[row.adRunSlot],
                'font-bold white-space-nowrap pr-2': true,
              }"
            >
              <InputGroup class="align-items-center">
                <Button
                  :icon="rowDisabledStatus[row.adRunSlot]
                    ? 'pi pi-ban'
                    : 'pi pi-check-circle'"
                  size="small"
                  :severity="rowDisabledStatus[row.adRunSlot]
                    ? 'error'
                    : 'success'"
                  text
                  v-tooltip.top="rowDisabledStatus[row.adRunSlot]
                    ? 'Enable Row'
                    : 'Disable Row'"
                  @click="onToggleRow(!rowDisabledStatus[row.adRunSlot], row.adRunSlot)"
                />
                {{  row.adRunSlotLabel }}
              </InputGroup>
            </td>
            <td
              v-for="option in row.adLengths"
              :key="option.adLength"
            >
              <InputGroup>
                <InputNumber
                  class="w-full"
                  :modelValue="option.value"
                  :disabled="option.disabled"
                  @update:modelValue="onChangeCpm($event, row.adRunSlot, option.adLength)"
                  @focus="onInputFocus(row.adRunSlot, option.adLength)"
                  @blur="onInputBlur"
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  :pt="{
                    input: {
                      root: {
                        class: 'text-right',
                      },
                    },
                  }"
                />
                <Button
                  :icon="option.disabled
                    ? 'pi pi-ban'
                    : 'pi pi-check-circle'"
                  size="small"
                  :severity="option.disabled
                    ? 'error'
                    : 'success'"
                  text
                  v-tooltip.top="option.disabled
                    ? 'Enable Option'
                    : 'Disable Option'"
                  @click="onToggleOption(!option.disabled, row.adRunSlot, option.adLength)"
                />
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  AD_RUN_SLOT_PRE_ROLL,
  AD_RUN_SLOT_PRE_ROLL_LABEL,
  AD_RUN_SLOT_MID_ROLL,
  AD_RUN_SLOT_MID_ROLL_LABEL,
  AD_RUN_SLOT_POST_ROLL,
  AD_RUN_SLOT_POST_ROLL_LABEL,
  AD_LENGTH_30_SECONDS,
  AD_LENGTH_60_SECONDS,
  AD_LENGTH_90_SECONDS,
} from '@/constants';

const AD_LENGTH_HEADER_ROW = [
  {
    label: '30s',
    value: AD_LENGTH_30_SECONDS,
  },
  {
    label: '60s',
    value: AD_LENGTH_60_SECONDS,
  },
  {
    label: '90s',
    value: AD_LENGTH_90_SECONDS,
  },
];

const getMinimumBidCpm = (adPreferences, adRunSlot, adLength) => {
  const matchingItem = adPreferences.find((item) => item.adRunSlot === adRunSlot
    && item.length === adLength);

  return matchingItem && typeof matchingItem.min_cpm === 'number'
    ? matchingItem.min_cpm
    : 0;
};

const getDisabledStatus = (adPreferences, adRunSlot, adLength) => {
  const matchingItem = adPreferences.find((item) => item.adRunSlot === adRunSlot
    && item.length === adLength);

  return matchingItem && typeof matchingItem.disabled === 'boolean'
    ? matchingItem.disabled
    : false;
};

export default {
  props: {
    modelValue: {
      type: Array,
    },
  },
  computed: {
    // transform prop data into array format for UI table
    compiledAdPreferences() {
      return [
        {
          adRunSlot: AD_RUN_SLOT_PRE_ROLL,
          adRunSlotLabel: AD_RUN_SLOT_PRE_ROLL_LABEL,
          adLengths: [
            {
              adLength: AD_LENGTH_30_SECONDS,
              value: getMinimumBidCpm(
                this.modelValue,
                AD_RUN_SLOT_PRE_ROLL,
                AD_LENGTH_30_SECONDS,
              ),
              disabled: getDisabledStatus(
                this.modelValue,
                AD_RUN_SLOT_PRE_ROLL,
                AD_LENGTH_30_SECONDS,
              ),
            },
            {
              adLength: AD_LENGTH_60_SECONDS,
              value: getMinimumBidCpm(
                this.modelValue,
                AD_RUN_SLOT_PRE_ROLL,
                AD_LENGTH_60_SECONDS,
              ),
              disabled: getDisabledStatus(
                this.modelValue,
                AD_RUN_SLOT_PRE_ROLL,
                AD_LENGTH_60_SECONDS,
              ),
            },
            {
              adLength: AD_LENGTH_90_SECONDS,
              value: getMinimumBidCpm(
                this.modelValue,
                AD_RUN_SLOT_PRE_ROLL,
                AD_LENGTH_90_SECONDS,
              ),
              disabled: getDisabledStatus(
                this.modelValue,
                AD_RUN_SLOT_PRE_ROLL,
                AD_LENGTH_90_SECONDS,
              ),
            },
          ],
        },
        {
          adRunSlot: AD_RUN_SLOT_MID_ROLL,
          adRunSlotLabel: AD_RUN_SLOT_MID_ROLL_LABEL,
          adLengths: [
            {
              adLength: AD_LENGTH_30_SECONDS,
              value: getMinimumBidCpm(
                this.modelValue,
                AD_RUN_SLOT_MID_ROLL,
                AD_LENGTH_30_SECONDS,
              ),
              disabled: getDisabledStatus(
                this.modelValue,
                AD_RUN_SLOT_MID_ROLL,
                AD_LENGTH_30_SECONDS,
              ),
            },
            {
              adLength: AD_LENGTH_60_SECONDS,
              value: getMinimumBidCpm(
                this.modelValue,
                AD_RUN_SLOT_MID_ROLL,
                AD_LENGTH_60_SECONDS,
              ),
              disabled: getDisabledStatus(
                this.modelValue,
                AD_RUN_SLOT_MID_ROLL,
                AD_LENGTH_60_SECONDS,
              ),
            },
            {
              adLength: AD_LENGTH_90_SECONDS,
              value: getMinimumBidCpm(
                this.modelValue,
                AD_RUN_SLOT_MID_ROLL,
                AD_LENGTH_90_SECONDS,
              ),
              disabled: getDisabledStatus(
                this.modelValue,
                AD_RUN_SLOT_MID_ROLL,
                AD_LENGTH_90_SECONDS,
              ),
            },
          ],
        },
        {
          adRunSlot: AD_RUN_SLOT_POST_ROLL,
          adRunSlotLabel: AD_RUN_SLOT_POST_ROLL_LABEL,
          adLengths: [
            {
              adLength: AD_LENGTH_30_SECONDS,
              value: getMinimumBidCpm(
                this.modelValue,
                AD_RUN_SLOT_POST_ROLL,
                AD_LENGTH_30_SECONDS,
              ),
              disabled: getDisabledStatus(
                this.modelValue,
                AD_RUN_SLOT_POST_ROLL,
                AD_LENGTH_30_SECONDS,
              ),
            },
            {
              adLength: AD_LENGTH_60_SECONDS,
              value: getMinimumBidCpm(
                this.modelValue,
                AD_RUN_SLOT_POST_ROLL,
                AD_LENGTH_60_SECONDS,
              ),
              disabled: getDisabledStatus(
                this.modelValue,
                AD_RUN_SLOT_POST_ROLL,
                AD_LENGTH_60_SECONDS,
              ),
            },
            {
              adLength: AD_LENGTH_90_SECONDS,
              value: getMinimumBidCpm(
                this.modelValue,
                AD_RUN_SLOT_POST_ROLL,
                AD_LENGTH_90_SECONDS,
              ),
              disabled: getDisabledStatus(
                this.modelValue,
                AD_RUN_SLOT_POST_ROLL,
                AD_LENGTH_90_SECONDS,
              ),
            },
          ],
        },
      ];
    },
    rowDisabledStatus() {
      const allAdLengthsAreDisabled = (adRunSlot) => this.modelValue
        .filter((item) => item.adRunSlot === adRunSlot && item.disabled === true)
        .length === 3;

      return {
        [AD_RUN_SLOT_PRE_ROLL]: allAdLengthsAreDisabled(AD_RUN_SLOT_PRE_ROLL),
        [AD_RUN_SLOT_MID_ROLL]: allAdLengthsAreDisabled(AD_RUN_SLOT_MID_ROLL),
        [AD_RUN_SLOT_POST_ROLL]: allAdLengthsAreDisabled(AD_RUN_SLOT_POST_ROLL),
      };
    },
    columnDisabledStatus() {
      const allAdRunSlotsAreDisabled = (adLength) => this.modelValue
        .filter((item) => item.length === adLength && item.disabled === true)
        .length === 3;

      return {
        [AD_LENGTH_30_SECONDS]: allAdRunSlotsAreDisabled(AD_LENGTH_30_SECONDS),
        [AD_LENGTH_60_SECONDS]: allAdRunSlotsAreDisabled(AD_LENGTH_60_SECONDS),
        [AD_LENGTH_90_SECONDS]: allAdRunSlotsAreDisabled(AD_LENGTH_90_SECONDS),
      };
    },
  },
  data() {
    return {
      AD_LENGTH_HEADER_ROW,
      focusedParams: {},
    };
  },
  methods: {
    onInputFocus(adRunSlot, adLength) {
      this.focusedParams = {
        [adRunSlot]: true,
        [adLength]: true,
      };
    },
    onInputBlur() {
      this.focusedParams = {};
    },
    onChangeCpm(value, adRunSlot, adLength) {
      const updatedModelValue = this.modelValue.map((item) => {
        const itemCopy = {
          ...item,
        };

        if (item.adRunSlot === adRunSlot && item.length === adLength) {
          itemCopy.min_cpm = value;
        }

        return itemCopy;
      });

      this.$emit('update:modelValue', updatedModelValue);
    },
    onToggleOption(disable, adRunSlot, adLength) {
      const updatedModelValue = this.modelValue.map((item) => {
        const itemCopy = {
          ...item,
        };

        if (item.adRunSlot === adRunSlot && item.length === adLength) {
          itemCopy.disabled = disable;
        }

        return itemCopy;
      });

      this.$emit('update:modelValue', updatedModelValue);
    },
    onToggleRow(disable, adRunSlot) {
      const updatedModelValue = this.modelValue.map((item) => {
        const itemCopy = {
          ...item,
        };

        if (item.adRunSlot === adRunSlot) {
          itemCopy.disabled = disable;
        }

        return itemCopy;
      });

      this.$emit('update:modelValue', updatedModelValue);
    },
    onToggleColumn(disable, adLength) {
      const updatedModelValue = this.modelValue.map((item) => {
        const itemCopy = {
          ...item,
        };

        if (item.length === adLength) {
          itemCopy.disabled = disable;
        }

        return itemCopy;
      });

      this.$emit('update:modelValue', updatedModelValue);
    },
  },
};
</script>

<style lang="scss">
.minimum-bid-cpm-table {
  min-width: 500px;
}
</style>
