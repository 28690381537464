<template>
  <Dialog
    :visible="visible"
    dismissableMask
    modal
    header="Contact Us"
    @update:visible="$emit('update:visible', $event)"
  >
    <div>
      Have any questions or comments? Contact our support.
    </div>

    <div class="mt-2">
      <a
        class="text-primary"
        :href="mailTo"
      >
        Email
        {{ SUPPORT_EMAIL }}
        <i class="text-sm pi pi-external-link" />
      </a>
    </div>

    <template #footer>
      <div class="flex justify-content-end">
        <Button
          text
          plain
          label="Close"
          @click="$emit('update:visible', false)"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { SUPPORT_EMAIL } from '@/constants';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mailTo() {
      const subject = 'Ekoz.ai App Contact Form';
      return `mailto:${SUPPORT_EMAIL}?subject=${subject}`;
    },
  },
  data() {
    return {
      SUPPORT_EMAIL,
    };
  },
};
</script>
