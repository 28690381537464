<template>
  <Card>
    <template #content>
      <div class="grid">
        <div class="col-12 sm:col sm:flex-grow-1">
          <h3 class="m-0">
            Impressions
          </h3>
        </div>
        <div class="col-12 sm:col sm:flex-grow-0">
          <Dropdown
            v-model="selectedChartInterval"
            :options="CHART_INTERVAL_OPTIONS"
            optionLabel="label"
            optionValue="value"
          />
        </div>
      </div>
      <Skeleton
        v-if="isLoading"
        class="h-15rem"
      />
      <Chart
        v-show="!isLoading"
        class="h-15rem"
        type="line"
        :data="chartData"
        :options="chartOptions"
      />
    </template>
  </Card>
</template>

<script>
const CHART_INTERVAL_DAILY = 'daily';
const CHART_INTERVAL_CUMULATIVE = 'cumulative';

const CHART_INTERVAL_OPTIONS = [
  {
    value: CHART_INTERVAL_DAILY,
    label: 'Daily',
  },
  {
    value: CHART_INTERVAL_CUMULATIVE,
    label: 'Cumulative',
  },
];

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    impressions: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    chartData() {
      const documentStyle = getComputedStyle(document.documentElement);

      let impressions = [];
      switch (this.selectedChartInterval) {
        case CHART_INTERVAL_DAILY:
          impressions = this.impressions.map(({
            count,
            timestamp,
          }) => ({
            x: timestamp,
            y: count,
          }));
          break;
        case CHART_INTERVAL_CUMULATIVE:
          impressions = this.impressions.reduce((acc, {
            count,
            timestamp,
          }) => {
            const previousDaysCount = acc.reduce((innerAcc, { y }) => innerAcc + y, 0);
            const currentCount = previousDaysCount + count;

            acc.push({
              x: timestamp,
              y: currentCount,
            });

            return acc;
          }, []);
          break;
        default:
          // do nothing;
      }

      return {
        datasets: [
          {
            label: 'Impressions',
            data: impressions,
            fill: false,
            borderColor: documentStyle.getPropertyValue('--blue-400'),
            borderWidth: 3,
            pointRadius: 0,
          },
        ],
      };
    },
  },
  data() {
    return {
      selectedChartInterval: CHART_INTERVAL_DAILY,
      CHART_INTERVAL_OPTIONS,
      chartOptions: {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
        },
        scales: {
          x: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
          y: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
      },
    };
  },
};
</script>
