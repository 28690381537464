<template>
  <PageContainer maxWidth="1200px">
    <PageTitle>Campaigns</PageTitle>
    <Message
      :closable="false"
      severity="secondary"
      :pt="{
        icon: {
          class: 'mt-1 text-xl align-self-start',
        },
        text: {
          class: 'font-normal',
        },
      }"
      icon="pi pi-info-circle"
    >
      Click into each campaign to review & approve your pending ads.
    </Message>
    <div
      class="grid"
    >
      <div class="col-12">
        <ImpressionsCard
          :adIds="adIds"
        />
      </div>
    </div>

    <div class="mt-4 overflow-y-auto">
      <DataView
        :style="{
          minWidth: '1000px',
        }"
        :value="sortedAds"
        :pt="{
          header: {
            class: 'bg-transparent border-0',
          },
          content: {
            class: 'bg-transparent',
          },
        }"
      >
        <template #header>
          <div class="grid grid-nogutter campaigns-table-header">
            <div
              class="col-4 cursor-pointer"
              @click="onSort(SORT_BY_NAME)"
              @keydown="onSort(SORT_BY_NAME)"
            >
              Name
              <i
                v-if="sort.by === SORT_BY_NAME"
                style="font-size: 12px"
                :class="{
                  'pi': true,
                  'pi-arrow-down': sort.order === SORT_ORDER_DESC,
                  'pi-arrow-up': sort.order === SORT_ORDER_ASC,
                }"
              />
            </div>
            <div
              class="col-2 cursor-pointer"
              @click="onSort(SORT_BY_END_DATE)"
              @keydown="onSort(SORT_BY_END_DATE)"
            >
              Duration
              <i
                v-if="sort.by === SORT_BY_END_DATE"
                style="font-size: 12px"
                :class="{
                  'pi': true,
                  'pi-arrow-down': sort.order === SORT_ORDER_DESC,
                  'pi-arrow-up': sort.order === SORT_ORDER_ASC,
                }"
              />
            </div>
            <!-- <div
              class="col-2 cursor-pointer"
              @click="onSort(SORT_BY_REVENUE)"
              @keydown="onSort(SORT_BY_REVENUE)"
            >
              Est. Payout
              <i
                v-if="sort.by === SORT_BY_REVENUE"
                style="font-size: 12px"
                :class="{
                  'pi': true,
                  'pi-arrow-down': sort.order === SORT_ORDER_DESC,
                  'pi-arrow-up': sort.order === SORT_ORDER_ASC,
                }"
              />
            </div> -->
            <div
              class="col-2 cursor-pointer"
              @click="onSort(SORT_BY_STATUS)"
              @keydown="onSort(SORT_BY_STATUS)"
            >
              Status
              <i
                v-if="sort.by === SORT_BY_STATUS"
                style="font-size: 12px"
                :class="{
                  'pi': true,
                  'pi-arrow-down': sort.order === SORT_ORDER_DESC,
                  'pi-arrow-up': sort.order === SORT_ORDER_ASC,
                }"
              />
            </div>
            <div class="col-4">
              Analytics
            </div>
          </div>
        </template>
      </DataView>
      <!--
        render loading & ads outside of DataView. The re-rendering caused by DataView is
        causing bugs with charts rendered in AdRow
      -->
      <ul
        v-if="adsStore.adsAreLoading"
        class="campaigns-list list-none p-0 m-0"
      >
        <li
          v-for="index in 3"
          :key="index"
        >
          <AdLoadingRow />
        </li>
      </ul>
      <ul
        v-show="!adsStore.adsAreLoading"
        class="campaigns-list list-none p-0 m-0"
      >
        <li
          v-for="item in sortedAds"
          :key="item.id"
        >
          <AdRow
            :ad="item"
            :impressions="analyticsStore.adImpressions[item.id] || []"
            :impressionsAreLoading="analyticsStore.adImpressionsAreLoading[item.id] || false"
            @pauseAd="onClickPauseAd"
            @resumeAd="onClickResumeAd"
          />
        </li>
      </ul>
    </div>

    <ConfirmDialog
      v-model:visible="pauseAdDialogData.visible"
      :isSubmitting="pauseAdDialogData.isSubmitting"
      header="Pause Ad"
      @confirm="onSubmitPauseAd"
    >
      <p>
        Are you sure you want to pause the ad
        <strong>
          {{ pauseAdDialogData.ad
            ? pauseAdDialogData.ad.campaign_name
            : '-'
          }}
        </strong>?
      </p>
    </ConfirmDialog>

    <ConfirmDialog
      v-model:visible="resumeAdDialogData.visible"
      :isSubmitting="resumeAdDialogData.isSubmitting"
      header="Resume Ad"
      @confirm="onSubmitResumeAd"
    >
      <p>
        Are you sure you want to resume the ad
        <strong>
          {{ resumeAdDialogData.ad
            ? resumeAdDialogData.ad.campaign_name
            : '-'
          }}
        </strong>
        ?
      </p>
    </ConfirmDialog>
  </PageContainer>
</template>

<script>
import { mapStores } from 'pinia';
import _orderBy from 'lodash/orderBy';
import {
  useAdsStore,
  useMyUserStore,
  useProgramsStore,
  useAnalyticsStore,
} from '@/stores';
import { parseMessageFromError } from '@/utils/errors';
import ConfirmDialog from '@/components/confirmDialog';
import * as api from '@/api';
import AdRow from './components/adRow';
import AdLoadingRow from './components/adLoadingRow';
import ImpressionsCard from './components/impressionsCard';

const SORT_ORDER_ASC = 'asc';
const SORT_ORDER_DESC = 'desc';

const SORT_BY_NAME = 'campaign_name';
const SORT_BY_END_DATE = 'end_date';
const SORT_BY_REVENUE = 'revenue';
const SORT_BY_STATUS = 'status';

export default {
  components: {
    AdRow,
    AdLoadingRow,
    ConfirmDialog,
    ImpressionsCard,
  },
  computed: {
    ...mapStores(useAdsStore, useMyUserStore, useProgramsStore, useAnalyticsStore),
    sortedAds() {
      const ads = this.myUserStore.myUser && this.myUserStore.myUser.organization_id
        ? this.adsStore.getAdsByOrganizationId(this.myUserStore.myUser.organization_id)
        : [];

      return _orderBy(ads, [this.sort.by], [this.sort.order]);
    },
    adIds() {
      const ads = this.myUserStore.myUser && this.myUserStore.myUser.organization_id
        ? this.adsStore.getAdsByOrganizationId(this.myUserStore.myUser.organization_id)
        : [];

      return ads.map((item) => item.id);
    },
  },
  data() {
    return {
      SORT_ORDER_ASC,
      SORT_ORDER_DESC,
      SORT_BY_NAME,
      SORT_BY_END_DATE,
      SORT_BY_REVENUE,
      SORT_BY_STATUS,
      sort: {
        by: SORT_BY_END_DATE,
        order: SORT_ORDER_ASC,
      },
      pauseAdDialogData: {
        visible: false,
        isSubmitting: false,
        ad: null,
      },
      resumeAdDialogData: {
        visible: false,
        isSubmitting: false,
        ad: null,
      },
    };
  },
  async mounted() {
    try {
      const organizationId = this.myUserStore.myOrganization
        ? this.myUserStore.myOrganization.id
        : null;

      await this.programsStore.getPrograms({
        organizationId,
      });

      await this.getAds();

      const ads = this.myUserStore.myUser && this.myUserStore.myUser.organization_id
        ? this.adsStore.getAdsByOrganizationId(this.myUserStore.myUser.organization_id)
        : [];

      ads.forEach((ad) => {
        this.analyticsStore.getAdImpressions({
          adId: ad.id,
          startDate: ad.start_date,
          endDate: ad.end_date,
        }).catch((error) => {
          console.log(`Error loading analytics for ad ${ad.id}`, error);
        });
      });
    } catch (error) {
      const message = parseMessageFromError(error, 'Error loading campaigns.');

      this.$toast.add({
        severity: 'error',
        detail: message,
      });
    }
  },
  methods: {
    onSort(sortBy) {
      if (this.sort.by === sortBy && this.sort.order === SORT_ORDER_ASC) {
        this.sort.order = SORT_ORDER_DESC;
      } else if (this.sort.by === sortBy && this.sort.order === SORT_ORDER_DESC) {
        this.sort.order = SORT_ORDER_ASC;
      } else {
        this.sort = {
          by: sortBy,
          order: SORT_ORDER_ASC,
        };
      }
    },
    getAds() {
      const organizationId = this.myUserStore.myOrganization
        ? this.myUserStore.myOrganization.id
        : null;

      const programs = this.programsStore.getProgramsByOrganizationId(organizationId);
      const programIds = programs.map((program) => program.id);

      return this.adsStore.getAds({
        programIds,
      });
    },
    async onClickPauseAd(ad) {
      this.pauseAdDialogData = {
        ...this.pauseAdDialogData,
        visible: true,
        ad,
      };
    },
    async onSubmitPauseAd() {
      try {
        this.pauseAdDialogData.isSubmitting = true;

        await api.pauseAd({
          adId: this.pauseAdDialogData.ad.id,
          action: 'pause',
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully paused ad',
        });

        this.pauseAdDialogData.visible = false;
        this.pauseAdDialogData.ad = null;

        await this.getAds();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error pausing ad.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.pauseAdDialogData.isSubmitting = false;
      }
    },
    async onClickResumeAd(ad) {
      this.resumeAdDialogData = {
        ...this.resumeAdDialogData,
        visible: true,
        ad,
      };
    },
    async onSubmitResumeAd() {
      try {
        this.resumeAdDialogData.isSubmitting = true;

        await api.pauseAd({
          adId: this.resumeAdDialogData.ad.id,
          action: 'resume',
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully resumed ad',
        });

        this.resumeAdDialogData.visible = false;
        this.resumeAdDialogData.ad = null;

        await this.getAds();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error resuming ad.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.resumeAdDialogData.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-table-header {
  color: var(--gray-400);
  font-size: 0.875rem;
}
.campaigns-list {
  min-width: 1000px;

  li:not(:first-child) {
    margin-top: 1rem;
  }
}
</style>
