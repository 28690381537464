import _get from 'lodash/get';

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

// this validation is the same that yup uses
export const validateEmail = (email) => (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/).test(email);

export const isJsonString = (value) => {
  try {
    JSON.parse(value);
  } catch {
    return false;
  }
  return true;
};

/**
 * Get value from data based on path. This also validates the value is of a certain type.
 * If is not of the correct type, the default value is used. This is important when using the
 * scrapeProgramMetadata endpoint because it will sometimes
 * return an empty array when you would expect an empty string
 *
 * @param {object} data
 * @param {string} path
 * @param {mixed} valueDefault
 * @param {string} valueType typeof value ('string', 'number', 'object', 'undefined')
 * @returns {mixed}
 */
export const getSanitizedValue = (data, path, valueDefault, valueType) => {
  const value = _get(data, path, valueDefault);

  // eslint-disable-next-line valid-typeof
  return typeof value === valueType
    ? value
    : valueDefault;
};
