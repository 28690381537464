<template>
  <Message
    severity="success"
    :closable="false"
    :pt="{
      icon: {
        class: 'mt-1 text-xl align-self-start',
      },
      text: {
        class: 'font-normal',
      },
    }"
  >
    Your organization has already set up a payment method.
    Have questions in regards to payment or want
    to change your payment method? Contact our
    <a
      class="text-primary"
      :href="mailTo"
    >support.</a>
  </Message>
</template>

<script>
import { SUPPORT_EMAIL } from '@/constants';

export default {
  computed: {
    mailTo() {
      const subject = 'Ekoz.ai Payment Question';
      return `mailto:${SUPPORT_EMAIL}?subject=${subject}`;
    },
  },
};
</script>
