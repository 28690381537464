<template>
  <PageContainer maxWidth="1200px">
    <div class="grid">
      <div class="col-12 sm:col-6">
        <router-link
          :to="{
            name: ROUTE_HOSTING_PLATFORM_CAMPAIGNS,
          }"
        >
          <Button
            class="text-sm p-0"
            icon="pi pi-arrow-left"
            link
            label="Back to campaigns"
          />
        </router-link>
      </div>

      <div class="col-12 sm:col-6 flex justify-content-end">
        <template v-if="approvalButtonsAreVisible">
          <Button
            icon="pi pi-upload"
            severity="success"
            outlined
            label="Upload Ad"
            @click="uploadAdDialogIsVisible = true"
          />
          <Divider layout="vertical" />
          <Button
            text
            label="Decline"
            severity="secondary"
            @click="disapproveDialogIsVisible = true"
          />
          <Button
            class="ml-2"
            label="Accept Request"
            @click="approveDialogIsVisible = true"
          />
        </template>

        <Button
          v-if="adIsActive"
          label="Pause Ad"
          outlined
          :loading="isPauseSubmitting"
          @click="isPauseDialogVisible = true"
        />
        <Button
          v-if="adIsPaused"
          label="Resume Ad"
          outlined
          :loading="isResumeSubmitting"
          @click="isResumeDialogVisible = true"
        />
      </div>
    </div>

    <!-- TODO - add loading state -->
    <!-- TODO - add error state? -->

    <template v-if="compiledAd">
      <!-- header -->
      <div class="grid line-height-3 mt-3">
        <div class="col-12 md:col">
          <span class="text-sm text-gray-400">
            {{ compiledAd.org_name || '-' }}
          </span><br />
          <span class="text-lg font-bold">
            {{ compiledAd.campaign_name || '-' }}
          </span><br />
          <Chip
            v-if="statusChip"
            :class="[statusChip.class, 'mt-2 text-xs']"
            :label="statusChip.value"
            v-tooltip.top="'Status'"
          />
        </div>

        <div class="col-6 md:col">
          <span class="text-sm text-gray-400">
            Duration
          </span><br />
          <span class="text-lg font-bold">
            {{ compiledAd.durationInDays }}
            day{{ compiledAd.durationInDays === 1 ? '' : 's' }}
          </span>
        </div>

        <div class="col-6 md:col">
          <span class="text-sm text-gray-400">
            Ends {{ compiledAd.endDateDisplay }}
          </span><br />
          <span class="text-lg font-bold">
            {{ compiledAd.durationSubtext }}
          </span>
        </div>
      </div>

      <Card
        class="mt-3 bg-gray-100"
        :pt="{
          body: {
            class: 'p-2'
          }
        }"
      >
        <template #content>
          <div class="flex">
            <div class="flex flex-grow-1 px-2">
              <audio
                class="w-full"
                controls
                :src="compiledAd.s3_signed_url"
              />
            </div>
            <!-- <Button
              class="text-sm"
              label="Transcribe"
              text
            /> -->
          </div>
        </template>
      </Card>

      <div class="flex">
        <h2>
          Campaign Details
        </h2>
      </div>
      <CampaignImpressionsCard
        :isLoading="analyticsStore.adImpressionsAreLoading[compiledAd.id] || false"
        :impressions="this.analyticsStore.adImpressions[this.$route.params.adId] || []"
      />

      <div class="mt-4">
        <h2>
          General Information
        </h2>
        <ul class="information-list">
          <li>
            <span class="text-sm text-gray-500">Target gender</span><br />
            <Chip class="bg-white px-3 py-2 font-bold border-gray-200 mt-2 border-1">
              {{ compiledAd.genderLabel }}
            </Chip>
          </li>
          <li>
            <span class="text-sm text-gray-500">Ages</span><br />
            <Chip
              v-for="item in compiledAd.targetAges"
              :key="item"
              class="bg-white px-3 py-2 font-bold border-gray-200 mt-2 border-1"
            >
              {{ item }}
            </Chip>
          </li>
          <li v-if="compiledAd.targetCategoryLabels.length > 0">
            <span class="text-sm text-gray-500">Categories</span><br />
            <Chip
              v-for="item in compiledAd.targetCategoryLabels"
              :key="item"
              class="bg-white px-3 py-2 font-bold border-gray-200 mt-2 border-1"
            >
              {{ item }}
            </Chip>
          </li>
          <li>
            <span class="text-sm text-gray-500">Ad run slot</span><br/>
            <Chip class="bg-white px-3 py-2 font-bold border-gray-200 mt-2 border-1">
              {{ compiledAd.ad_run_slot }}
            </Chip>
          </li>
        </ul>
      </div>
    </template>

    <ConfirmDialog
      v-model:visible="approveDialogIsVisible"
      header="Confirm Request"
      @confirm="onApproveAd"
      :isSubmitting="adApprovalIsSubmitting"
    >
      <p>
        Are you sure you want to accept this campaign request?
      </p>
      <p>
        Once approved, your podcast will begin running the ad.
      </p>
    </ConfirmDialog>

    <PromptDialog
      v-model:visible="disapproveDialogIsVisible"
      header="Decline Request"
      @confirm="onDisapproveAd"
      :isSubmitting="adDisapprovalIsSubmitting"
    >
      <p>
        Are you sure you want to decline this campaign request?
      </p>
      <p>
        Please provide a reason below
      </p>
    </PromptDialog>

    <UploadAdDialog
      v-model:visible="uploadAdDialogIsVisible"
      :isSubmitting="isUploadAdSubmitting"
      @submit="onSubmitUploadAd"
    />

    <ConfirmDialog
      v-model:visible="isPauseDialogVisible"
      :isSubmitting="isPauseSubmitting"
      header="Pause Ad"
      @confirm="onSubmitPauseAd"
    >
      <p>
        Are you sure you want to pause the ad for
        <strong>
          {{ compiledAd
            ? compiledAd.campaign_name
            : '-'
          }}
        </strong>
        ?
      </p>
    </ConfirmDialog>

    <ConfirmDialog
      v-model:visible="isResumeDialogVisible"
      :isSubmitting="isResumeSubmitting"
      header="Resume Ad"
      @confirm="onSubmitResumeAd"
    >
      <p>
        Are you sure you want to resume the ad for
        <strong>
          {{ compiledAd
            ? compiledAd.campaign_name
            : '-'
          }}
        </strong>
        ?
      </p>
    </ConfirmDialog>
  </PageContainer>
</template>

<script>
import { DateTime } from 'luxon';
import { mapStores } from 'pinia';
import { ROUTE_HOSTING_PLATFORM_CAMPAIGNS } from '@/router/routes';
import {
  useAdsStore,
  useCategoriesStore,
  useMyUserStore,
  useAnalyticsStore,
} from '@/stores';
import { parseMessageFromError } from '@/utils/errors';
import { isJsonString } from '@/utils/helpers';
import { AD_STATUS_ACTIVE, AD_STATUS_PAUSED, GENDER_OPTIONS } from '@/constants';
import ConfirmDialog from '@/components/confirmDialog';
import PromptDialog from '@/components/promptDialog';
import * as api from '@/api';
import { getAdStatusChip } from '@/utils/statuses';
import UploadAdDialog from '@/components/uploadAdDialog';
import CampaignImpressionsCard from '@/components/campaignImpressionsCard';

export default {
  components: {
    ConfirmDialog,
    PromptDialog,
    UploadAdDialog,
    CampaignImpressionsCard,
  },
  computed: {
    ...mapStores(useMyUserStore, useAdsStore, useCategoriesStore, useAnalyticsStore),
    compiledAd() {
      const ad = this.adsStore.getAdById(this.$route.params.adId);

      if (!ad) return null;

      const durationInDays = Math.ceil(
        DateTime.fromISO(ad.end_date)
          .diff(DateTime.fromISO(ad.start_date), ['days']).days,
      );

      const endDateDisplay = DateTime.fromISO(ad.end_date)
        .toLocaleString(DateTime.DATE_SHORT);

      const campaignHasStarted = DateTime.fromISO(ad.start_date).diffNow('days').days < 0;
      let durationSubtext = '';

      if (campaignHasStarted) {
        const daysLeft = Math.max(
          Math.ceil(DateTime.fromISO(ad.end_date).diffNow('days').days),
          0,
        );

        durationSubtext = `${daysLeft} day${daysLeft === 1 ? '' : 's'} left`;
      } else {
        const daysUntilStart = Math.ceil(DateTime.fromISO(ad.start_date).diffNow('days').days);

        durationSubtext = `${daysUntilStart} day${daysUntilStart === '1' ? '' : 's'} until start`;
      }

      const targetAges = isJsonString(ad.target_ages)
        ? Object.keys(JSON.parse(ad.target_ages))
        : [];

      const targetCategories = isJsonString(ad.target_categories)
        ? JSON.parse(ad.target_categories)
        : [];

      const targetCategoryLabels = targetCategories.reduce((acc, item) => {
        const matchingCategory = this.categoriesStore.categoryDictionary[Number(item)];

        if (matchingCategory) acc.push(matchingCategory.name);

        return acc;
      }, []);

      const genderLabel = GENDER_OPTIONS.some((item) => item.value === ad.target_gender)
        ? GENDER_OPTIONS.find((item) => item.value === ad.target_gender).label
        : ad.target_gender;

      return {
        ...ad,
        durationInDays,
        endDateDisplay,
        durationSubtext,
        targetAges,
        targetCategoryLabels,
        genderLabel,
      };
    },
    approvalButtonsAreVisible() {
      const ad = this.adsStore.getAdById(this.$route.params.adId);

      if (!ad || !ad.generated_ad_id) return false;

      return ad.approved === 0 && ad.disapproved_reason === null;
    },
    adIsActive() {
      return this.compiledAd && this.compiledAd.status === AD_STATUS_ACTIVE;
    },
    adIsPaused() {
      return this.compiledAd && this.compiledAd.status === AD_STATUS_PAUSED;
    },
    statusChip() {
      if (!this.compiledAd) return null;

      return getAdStatusChip(this.compiledAd.status);
    },
  },
  data() {
    return {
      ROUTE_HOSTING_PLATFORM_CAMPAIGNS,
      approveDialogIsVisible: false,
      adApprovalIsSubmitting: false,
      disapproveDialogIsVisible: false,
      adDisapprovalIsSubmitting: false,
      uploadAdDialogIsVisible: false,
      isUploadAdSubmitting: false,
      isPauseDialogVisible: false,
      isPauseSubmitting: false,
      isResumeDialogVisible: false,
      isResumeSubmitting: false,
    };
  },
  mounted() {
    this.getAd();
  },
  methods: {
    async getAd() {
      try {
        await this.adsStore.getAds({
          adId: this.$route.params.adId,
        });

        const ad = this.adsStore.getAdById(this.$route.params.adId);

        await this.analyticsStore.getAdImpressions({
          adId: ad.id,
          startDate: ad.start_date,
          endDate: ad.end_date,
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error loading campaign.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onApproveAd() {
      try {
        this.adApprovalIsSubmitting = true;

        await api.setApprovalGeneratedAd({
          generatedAdId: this.compiledAd.generated_ad_id,
          approved: 1,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully approved campaign.',
        });

        this.approveDialogIsVisible = false;

        this.getAd();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error approving campaign');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.adApprovalIsSubmitting = false;
      }
    },
    async onDisapproveAd(input) {
      try {
        this.adDisapprovalIsSubmitting = true;

        await api.setApprovalGeneratedAd({
          generatedAdId: this.compiledAd.generated_ad_id,
          approved: 0,
          disapprovedReason: input,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully declined campaign.',
        });

        this.disapproveDialogIsVisible = false;

        this.getAd();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error declining campaign');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.adDisapprovalIsSubmitting = false;
      }
    },
    async onSubmitUploadAd(adFile) {
      try {
        this.isUploadAdSubmitting = true;

        await api.uploadPremadeAd({
          adFile,
          userId: this.myUserStore.userId,
          programId: this.compiledAd.program_id,
          campaignId: this.compiledAd.campaign_id,
          adId: this.compiledAd.id,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully uploaded ad',
        });

        this.uploadAdDialogIsVisible = false;

        this.getAd();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error uploading ad.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isUploadAdSubmitting = false;
      }
    },
    async onSubmitPauseAd() {
      try {
        this.isPauseSubmitting = true;

        await api.pauseAd({
          adId: this.compiledAd.id,
          action: 'pause',
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully paused ad',
        });

        this.isPauseDialogVisible = false;

        this.getAd();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error pausing ad.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isPauseSubmitting = false;
      }
    },
    async onSubmitResumeAd() {
      try {
        this.isResumeSubmitting = true;

        await api.pauseAd({
          adId: this.compiledAd.id,
          action: 'resume',
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully resumed ad',
        });

        this.isResumeDialogVisible = false;

        this.getAd();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error resuming ad.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isResumeSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.information-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  >li {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-right: 0;
    }

    .p-chip {
      margin-right: 0.25rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
